<script>
import Swal from "sweetalert2";

import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      name: {
        val: "",
        isValid: true,
      },
      userName: {
        val: "",
        isValid: true,
      },
      password: {
        val: "",
        isValid: true,
      },
      role: {
        val: "",
        isValid: true,
      },
      roles: [],
      msg: "",
      users: [],
      id: "",
      formIsValid: true,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true
    },
    reloadPage() {
      window.location.reload();
    },
    validateForm() {
      if (this.name.val === "") {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (this.userName.val === "") {
        this.userName.isValid = false;
        this.formIsValid = false;
      }
      if (this.password.val === "") {
        this.password.isValid = false;
        this.formIsValid = false;
      }
      if (this.role.val === "") {
        this.role.isValid = false;
        this.formIsValid = false;
      } else {
        this.formIsValid = true;
      }
    },
    async getUsers() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/admin/user/"
      );
      this.users = response.data;
      const lastEl = this.users[this.users.length - 1];
      this.id = lastEl.id;
      this.successmsg();
    },

    async addUsers() {
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        formdata.append("name", this.name.val);
        formdata.append("userName", this.userName.val);
        formdata.append("password", this.password.val);
        formdata.append("role", this.role.val);

        const result = await axios.post(
          "https://api.doctosoft.com/index.php/api/admin/user",
          formdata
        );
        this.msg = result.data;
        this.getUsers();
        this.$router.push("/admin/all-users");
      }
    },

    errormsg() {
      Swal.fire(
        "Invalid Form",
        "Please fix the errors and try again later",
        "error"
      );
    },
    successmsg() {
      Swal.fire(
        "Good job!",
        "Created new user successfully " ,
        "success"
      );
    },
    async getData() {
      await axios
        .get("https://api.doctosoft.com/index.php/api/role")
        .then((response) => {
          this.roles = response.data;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/all-users')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/all-users')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addUsers">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addUsers">
            <div class="mb-3" :class="{ invalid: !name.isValid }">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="name.val" placeholder="Enter name"
                @blur="clearValidity('name')" />
            </div>

            <div class="mb-3" :class="{ invalid: !userName.isValid }">
              <label for="userName" class="form-label">userName <span class="text-danger">*</span></label>
              <input type="userName" class="form-control" v-model="userName.val" placeholder="Enter userName"
                @blur="clearValidity('userName')" />
            </div>

            <div class="mb-3" :class="{ invalid: !password.isValid }">
              <label for="password" class="form-label">Password <span class="text-danger">*</span></label>
              <input type="password" class="form-control" v-model="password.val" placeholder="Enter password"
                @blur="clearValidity('password')" />
            </div>

            <div class="mb-3" :class="{ invalid: !role.isValid }">
              <label for="role" class="form-label">Role</label>
              <select class="form-select" aria-label="size 3 select example" v-model="role.val"
                @blur="clearValidity('role')">
                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ role.name }}
                </option>
              </select>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

